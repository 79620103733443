import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import { CONST } from '@/config/config';
import { PlaceResponse } from '@/store/place/types';
import moment from 'moment';

@Module({ name: 'place', namespaced: true })
export default class PlaceModule extends VuexModule {
  public poingApi: string = 'https://poing-api-qa.mypoing.com/api';
  public placeData: PlaceResponse | null = null;
  public placeImageData: any = null;
  public placeReview: any = null;
  public otherProductData: any | null = null;
  public placeMenuData: any | null = null;
  public placeProductData: any | null = null;
  public placeTimeData: any | null = null;

  @Mutation
  public setPlaceData(response: PlaceResponse) {
    this.placeData = response;
  }

  @Mutation
  public setPlaceImage(response: any) {
    this.placeImageData = response;
  }

  @Mutation
  public setPlaceReview(response: any) {
    this.placeReview = response;
  }

  @Mutation
  public setOtherProduct(response: any) {
    this.otherProductData = response;
    // this.otherProductData = response;
    // this.otherProductData.data = filter(this.otherProductData.data, (row) => {
    //   return row.id !== app.$route.params.productId;
    // });
  }

  @Mutation
  public setPlaceMenu(response: any) {
    this.placeMenuData = response;
  }
  @Mutation
  public setPlaceProduct(response: any) {
    this.placeProductData = response;
  }
  @Mutation
  public setPlaceTime(response: any) {
    this.placeTimeData = response;
  }

  // 매장 정보 조회
  @Action
  public async fetchPlaceInfoData(id: string) {
    try {
      const res: any = await axios.get(`/place/${id}`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceData', res.data);
      }
      return res.data;
    } catch (e) {
      return e;
    }
  }

  // 매장 이미지 조회
  @Action
  public async fetchPlaceImage(id: string) {
    try {
      const res = await axios.get(`place/${id}/image`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceImage', res.data);
      }
    } catch (e) {
      return e;
    }
  }

  // 매장 리뷰 조회
  @Action
  public async fetchPlaceReview(params) {
    try {
      const res = await axios.get('/review', {
        params
      });
      if (res && res.data) {
        this.context.commit('setPlaceReview', res.data);
      }
      return Promise.resolve(res && res.data);
    } catch (error) {
      console.log(error);
    }
  }
  // 같은 매장의 다른 티켓
  @Action
  public async fetchOtherProduct(params: any) {
    try {
      const res = await axios.get(`/place/${params.placeId}/product`, {
        params: {
          except_product_id: params.productId
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setOtherProduct', res.data);
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }
  // 매장 상세 메뉴
  @Action
  public async fetchPlaceMenu(placeId: any) {
    try {
      const res = await axios.get(`/place/${placeId}/menu`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceMenu', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 매장내 티켓
  @Action
  public async fetchPlaceProduct(placeId: any) {
    try {
      const res = await axios.get(`/place/${placeId}/product`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceProduct', res.data);
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }
  // 매장 상세 타임블럭
  @Action
  public async fetchPlaceTime(placeId: any) {
    try {
      const res = await axios.get(`/place/${placeId}/timeslot`, {
        params: {
          date: moment().format('YYYY-MM-DD'),
          person: 2
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceTime', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 예약취소
  @Action
  public async deleteCancel(params) {
    try {
      const res = await axios.delete(`/reservation/${params.id}`, {
        params: {
          refund_rate: params.refund_rate
        }
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }

  get placeInfoData() {
    if (!this.placeData) {
      return '';
    } else {
      return this.placeData.data;
    }
  }

  get placeImage() {
    if (!this.placeImageData) {
      return '';
    } else {
      return this.placeImageData.data;
    }
  }

  get placeReviewData() {
    if (!this.placeReview) {
      return '';
    } else {
      return this.placeReview.data;
    }
  }
  get otherProduct() {
    if (!this.otherProductData) {
      return '';
    } else {
      return this.otherProductData.data;
    }
  }
  get placeMenu() {
    if (!this.placeMenuData) {
      return '';
    } else {
      return this.placeMenuData.data;
    }
  }

  get placeMenuUnit() {
    if (!this.placeMenuData) {
      return '';
    } else {
      return this.placeMenuData.meta;
    }
  }
  get placeProduct() {
    if (!this.placeProductData) {
      return '';
    } else {
      return this.placeProductData.data;
    }
  }
  get placeTime() {
    if (!this.placeTimeData) {
      return '';
    } else {
      return this.placeTimeData.data;
    }
  }
}
