import '@babel/polyfill';
import 'core-js';
import '@/assets/styles/quasar.scss';
import '@/assets/sass/app.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './config/quasar';
import './config/plugins';
import './config/classComponentHook';

Vue.config.productionTip = false;

const app = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');

export default app;
