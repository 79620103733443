
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Translator } from 'vue-google-translate';
import { Prop } from 'vue-property-decorator';

@Component({ components: { Translator } })
export default class MainHeader extends Vue {
  @Prop()
  public selectLang!: any;
  public transOpen: boolean = false;
  public languages: any = [
    {
      code: 'en|ko',
      title: 'KR',
      flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/kr.svg'
    },
    {
      code: 'en|en',
      title: 'EN',
      flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
    },
    {
      code: 'en|ja',
      title: 'JP',
      flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/jp.svg',
    }
  ];

  public languageSelectedHandler() {
    this.changeLang();
  }

  public changeLang() {
    const google: any = document.getElementsByClassName('goog-te-combo');
    if (!google[0].value) {
      this.$emit('lang', 'ko');
    } else {
      this.$emit('lang', google[0].value);
    }
    this.transOpen = false;
  }

  get langData() {
    let data: any = [];
    if (
      this.$route.path.includes('/product/') ||
      this.$route.path.includes('/bicena') ||
      this.$route.path.includes('/payment/result/success')
    ) {
      if (this.selectLang === 'ko' || this.selectLang === '') {
        data = [
          {
            code: 'en|en',
            title: 'EN',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
          }
        ];
      } else if (this.selectLang === 'en') {
        data = [
          {
            code: 'en|ko',
            title: 'KR',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/kr.svg'
          }
        ];
      }
    } else {
      if (this.selectLang === 'ko' || this.selectLang === '') {
        data = [
          {
            code: 'en|en',
            title: 'EN',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
          },
          {
            code: 'en|ja',
            title: 'JP',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/jp.svg',
          }
        ];
      } else if (this.selectLang === 'en') {
        data = [
          {
            code: 'en|ko',
            title: 'KR',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/kr.svg'
          },
          {
            code: 'en|ja',
            title: 'JP',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/jp.svg',
          }
        ];
      } else if (this.selectLang === 'ja') {
        data = [
          {
            code: 'en|ko',
            title: 'KR',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/kr.svg'
          },
          {
            code: 'en|en',
            title: 'EN',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
          }
        ]
      } else {
        data = [
          {
            code: 'en|en',
            title: 'EN',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
          },
          {
            code: 'en|ja',
            title: 'JP',
            flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/jp.svg',
          }
        ];
      }
    }
    return data;
  }
  get langDataAll() {
    return [
      {
        code: 'en|ko',
        title: 'KR',
        flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/kr.svg'
      },
      {
        code: 'en|en',
        title: 'EN',
        flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
      },
      {
        code: 'en|ja',
        title: 'JP',
        flagIconUrl: 'https://flagicons.lipis.dev/flags/4x3/jp.svg',
      }
    ];
  }
}
