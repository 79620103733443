import Vue from 'vue';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
// import iconSet from 'quasar/icon-set/fontawesome-v5.js';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import {
  Quasar,
  QSpace,
  QLayout,
  QHeader,
  QFooter,
  QField,
  QForm,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QImg,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QInput,
  QCarousel,
  QCarouselControl,
  QCarouselSlide,
  QRating,
  QCard,
  QCardSection,
  QCardActions,
  Notify,
  QTabs,
  QTab,
  QRouteTab,
  QTabPanels,
  QTabPanel,
  QSplitter,
  QSeparator,
  QAvatar,
  QDialog,
  ClosePopup,
  QExpansionItem,
  QToggle,
  QCheckbox,
  QRadio,
  QScrollObserver,
  QInfiniteScroll,
  QSpinner,
  QSpinnerDots,
  QSpinnerTail,
  QInnerLoading,
  QSlideTransition,
  Screen,
  QBtnToggle,
  QSelect,
  QRange,
  QDate
} from 'quasar';

Vue.use(Quasar, {
  config: {
    notify: {
      position: 'top',
      timeout: 1500,
      group: ''
    }
  },
  components: {
    QLayout,
    QSpace,
    QHeader,
    QFooter,
    QField,
    QForm,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QImg,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QInput,
    QCarousel,
    QCarouselControl,
    QCarouselSlide,
    QRating,
    QCard,
    QCardSection,
    QCardActions,
    QTabs,
    QTab,
    QTabPanels,
    QTabPanel,
    QRouteTab,
    QSplitter,
    QSeparator,
    QAvatar,
    QDialog,
    QToggle,
    QExpansionItem,
    QRadio,
    QCheckbox,
    QScrollObserver,
    QInfiniteScroll,
    QSpinner,
    QSpinnerDots,
    QSpinnerTail,
    QInnerLoading,
    QSlideTransition,
    QBtnToggle,
    QSelect,
    QRange,
    QDate
  },
  directives: {
    ClosePopup,
    QExpansionItem,
    QToggle,
    QCheckbox,
    QRadio
  },
  plugins: {
    Notify
  }
});

Screen.setSizes({
  md: 768
});
