import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from './axios';
import VueMq from 'vue-mq';
import VueClipboard from 'vue-clipboard2';
import VModal from 'vue-js-modal';
import VueMoment from 'vue-moment';
import VueCookies from 'vue-cookies';
import StarRating from 'vue-star-rating';
const moment = require('moment');
require('moment/locale/ko');
import InfiniteLoading from 'vue-infinite-loading';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VCalendar from 'v-calendar';
import VueHead from 'vue-head';
import Meta from 'vue-meta';

// vue-axios
Vue.use(VueAxios, axios);

Vue.use(VueMq, {
  breakpoints: {
    mobile: 767,
    tablet: 1200,
    desktop: Infinity
  }
});

Vue.use(VueClipboard);

Vue.use(VModal, {
  dynamic: true
});

Vue.use(VueMoment, {
  moment
});

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'waveDots'
    /* other props need to configure */
  },
  slots: {
    noResults: '',
    noMore: ''
  }
  // system: {
  //   throttleLimit: 50,
  //   /* other settings need to configure */
  // },
});

Vue.use(VueTextareaAutosize);

Vue.use(VCalendar, {
  componentPrefix: 'v',
  masks: {
    title: 'M월'
  }
});

Vue.use(VueCookies);
Vue.use(VueHead, {
  separator: '',
  complement: ''
});

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-ssr',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});
Vue.component('star-rating', StarRating);
