import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/layout/BaseLayout.vue'),
      children: [
        {
          path: '/system',
          name: 'system',
          component: () => import('@/pages/system/Index.vue')
        },
        {
          path: 'bicena',
          name: 'bicena',
          component: () => import('@/pages/place/Bicena.vue')
        },
        {
          path: ':reservationId',
          name: 'DepositMain',
          component: () => import('@/pages/deposit/Index.vue')
        },
        {
          path: 'result/:reservationId',
          name: 'DepositResult',
          component: () => import('@/pages/deposit/Result.vue')
        },
        {
          path: 'product/:productId',
          name: 'product',
          component: () => import('@/pages/product/Index.vue'),
          props: true
        },
        {
          path: 'place/:placeId',
          name: 'place',
          component: () => import('@/pages/place/Index.vue'),
          props: true
        },
        {
          path: '/place/:placeId/menu',
          name: 'placeMenu',
          component: () => import('@/pages/place/PlaceMenu.vue'),
          meta: {
            title: '메뉴',
            noneHeader: true
          }
        },
        {
          path: '/payment/:cartId',
          name: 'payment',
          props: true,
          component: () => import('@/pages/payment/Index.vue'),
          meta: {
            noneHeader: true
          }
        },
        {
          path: '/payment/result/:type',
          name: 'paymentResult',
          props: true,
          component: () => import('@/pages/payment/PaymentResult.vue')
        }
      ]
    }
  ]
});
router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/bicenaHall')) {
    if (process.env.NODE_ENV === 'production') {
      next({
        path: 'product/8324'
      });
      return;
    } else {
      next({
        path: 'product/7977'
      });
      return;
    }
  }
  if (to.path.startsWith('/bicenaRoom')) {
    if (process.env.NODE_ENV === 'production') {
      next({
        path: 'product/8328'
      });
      return;
    }
  }
  next();
});

export default router;
