import Vue from 'vue';
import Vuex from 'vuex';
import { getModule } from 'vuex-module-decorators';
import PlaceModule from '@/store/place';
import PaymentModule from '@/store/payment';
import ReservationModule from '@/store/reservation';
import TicketModule from '@/store/ticket';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    place: PlaceModule,
    payment: PaymentModule,
    reservation: ReservationModule,
    ticket: TicketModule
  }
});

export const placeModule = getModule(PlaceModule, store);
export const paymentModule = getModule(PaymentModule, store);
export const reservationModule = getModule(ReservationModule, store);
export const ticketModule = getModule(TicketModule, store);

export default store;
